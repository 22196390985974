export const counties = [
  "Apache",
  "Cochise",
  "Coconino",
  "Gila",
  "Graham",
  "Greenlee",
  "La Paz",
  "Maricopa",
  "Mohave",
  "Navajo",
  "Pima",
  "Pinal",
  "Santa Cruz",
  "Yavapai",
  "Yuma",
];

export const cities = [
  "Ajo",
  "Amado",
  "Apache Junction",
  "Arizona City",
  "Ash Fork",
  "Avondale",
  "Bagdad",
  "Benson",
  "Bisbee",
  "Black Canyon City",
  "Bouse",
  "Buckeye",
  "Bullhead City",
  "Cameron",
  "Camp Verde",
  "Carefree",
  "Casa Grande",
  "Catalina",
  "Cave Creek",
  "Chandler",
  "Chinle",
  "Chino Valley",
  "Cibecue",
  "Clarkdale",
  "Claypool",
  "Clifton",
  "Colorado City",
  "Congress",
  "Coolidge",
  "Cornville",
  "Cottonwood",
  "Dennehotso",
  "Dolan Springs",
  "Douglas",
  "Duncan",
  "Eagar",
  "Ehrenberg",
  "El Mirage",
  "Elgin",
  "Eloy",
  "Flagstaff",
  "Florence",
  "Fort Defiance",
  "Fountain Hills",
  "Fredonia",
  "Gadsden",
  "Ganado",
  "Gila Bend",
  "Gilbert",
  "Glendale",
  "Globe",
  "Golden Valley",
  "Goodyear",
  "Green Valley",
  "Hayden",
  "Holbrook",
  "Houck",
  "Huachuca City",
  "Jerome",
  "Kaibito",
  "Kayenta",
  "Keams Canyon",
  "Kearny",
  "Kingman",
  "Kykotsmovi Village",
  "Lake Havasu City",
  "Lake Montezuma",
  "Leupp",
  "Litchfield Park",
  "Lukachukai",
  "Mammoth",
  "Many Farms",
  "Marana",
  "Maricopa",
  "Mcnary",
  "Mesa",
  "Miami",
  "Mohave Valley",
  "Morenci",
  "Munds Park",
  "Naco",
  "Nazlini",
  "New River",
  "Nogales",
  "Oracle",
  "Page",
  "Paradise Valley",
  "Parker",
  "Parks",
  "Patagonia",
  "Paulden",
  "Payson",
  "Peach Springs",
  "Peoria",
  "Peridot",
  "Phoenix",
  "Pima",
  "Pine",
  "Pinon",
  "Pirtleville",
  "Prescott Valley",
  "Prescott",
  "Quartzsite",
  "Queen Creek",
  "Rio Verde",
  "Rock Point",
  "Round Rock",
  "Sacaton",
  "Safford",
  "Sahuarita",
  "Salome",
  "San Carlos",
  "San Luis",
  "San Manuel",
  "Scottsdale",
  "Second Mesa",
  "Sedona",
  "Seligman",
  "Sells",
  "Shonto",
  "Show Low",
  "Sierra Vista",
  "Snowflake",
  "Somerton",
  "Sonoita",
  "Springerville",
  "Stanfield",
  "Sun City West",
  "Sun City",
  "Sun Valley",
  "Superior",
  "Surprise",
  "Tacna",
  "Taylor",
  "Teec Nos Pos",
  "Tempe",
  "Thatcher",
  "Tolleson",
  "Tombstone",
  "Tonalea",
  "Tonto Basin",
  "Tsaile",
  "Tuba City",
  "Tubac",
  "Tucson",
  "Vail",
  "Wellton",
  "Wenden",
  "Whiteriver",
  "Wickenburg",
  "Willcox",
  "Williams",
  "Window Rock",
  "Winkelman",
  "Winslow",
  "Yarnell",
  "Young",
  "Youngtown",
  "Yuma",
];

export const userGroups = [
  "Educator",
  "Student",
  "Parent",
  "Farmer",
  "Gardener",
  "Organizer",
  "Administrator",
  "Volunteer",
];
