import { useEffect } from "react";
import "./Bingo.css"
import GatheringCardButton2 from "../components/GatheringCardButton2.js";

function Bingo() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToDownload = () => {
    const downloadSection = document.getElementById('download-section');
    downloadSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <div className="container-container">
        <br />
        <div className="container">
        <div className="body-container orange-white-container" style={{maxWidth: '600px'}}>
        </div>

          <div className="horizontal-line" />

          <div className="body-container brown-container">
          <h1 className="title2">How To Play</h1>
            <ul>
              <li className="myli">
                <b>Step 1:</b> <i>Plan</i>.{" "}Download your bingo card below and select which activities you'd like to do.
              </li>
              <br />
              <li className="myli">
                <b>Step 2:</b> <i>Activate</i>.{" "}Do the activities and take plenty of pictures. (Be sure to get photo sharing permission from anyone whose face is visible!)
              </li>
              <br />
              <li className="myli">
                <b>Step 3:</b> TAG & SHARE: After your activity is completed, share on Instagram as a POST using 
                <strong>#azf2sBINGO</strong> and <strong>#azf2s</strong>, and tag us{" "} 
                <a href="https://www.instagram.com/azf2s/" target="_blank" rel="noopener noreferrer">@azf2s</a>.
              </li>
            </ul>
          </div>
          
          
          <div className="horizontal-line-white" />
          
          <div className="body-container green-white-container" id="download-section">
            <h1 className="title2" style={{textAlign: 'center'}}>Download Bingo&nbsp;Card</h1>
            <br /><br />
        
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                  <GatheringCardButton2
                      onClick={() => {
                          window.open("/bingo-colorful.pdf", "_blank");
                      }}
                      color="#7A3C5F"
                      text="Colorful Version"
                  />

                  <GatheringCardButton2
                      onClick={() => {
                          window.open("/bingo-ink-saving.pdf", "_blank");
                      }}
                      color="#7A3C5F"
                      text="Ink Saving Version"
                  />
              </div>
              <ul>
              <li className="myli">
                <i>Note: Some spaces on your bingo card have clickable links on them. Use these to learn more and complete your square!</i>
              </li>
            </ul> 
          </div> 
          <br /><br />
        </div>
      </div> 
    </>
  );
}

export default Bingo;
