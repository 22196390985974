import { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [userSettings, setUserSettings] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const value = {
    isAuthenticated,
    setIsAuthenticated,
    user,
    setUser,
    isAdmin,
    setIsAdmin,
    userSettings,
    setUserSettings,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
