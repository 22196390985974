import "./Login.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { useCookies } from "react-cookie";
import PrimaryButton from "../components/PrimaryButton.js";
import { useAuth } from "../contexts/AuthContext";
import { Box, CircularProgress } from "@mui/material";
import '.././config';

function Login() {
  const [email_text, set_email_text] = useState("");
  const [pass_text, set_pass_text] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["uid"]);
  const { setIsAuthenticated, setUser, setUserSettings, user, setIsAdmin } =
    useAuth();
  const [isSigningIn, setIsSigningIn] = useState(false);

  // TODO: Verify that this regex is equal or stronger than the NodeBB requirements.
  // Does it even matter when using the API? Who knows.
  var passRegex =
    /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,100}$/;
  var emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  let error, email, password, emailLabel, passwordLabel;

  useEffect(() => {
    // Start at top of page
    window.scrollTo(0, 0);

    // DOM Element Selection
    email = document.querySelector(".email-login-input");
    password = document.querySelector(".password-login-input");
    emailLabel = document.querySelector(".email-label");
    passwordLabel = document.querySelector(".password-label");
    error = document.getElementById("li-error-text");

    // Dynamic email box
    email.addEventListener("focus", () => {
      emailLabel.classList.add("input-selected");
    });
    email.addEventListener("blur", () => {
      if (email.value.length == 0) {
        emailLabel.classList.remove("input-selected");
      } else {
        email.style.borderColor = "#666";
        emailLabel.style.color = "#000000de";
        if (password.value.length != 0) {
          error.style.visibility = "hidden";
        }
      }
    });

    // Dynamic password box
    password.addEventListener("focus", () => {
      passwordLabel.classList.add("input-selected");
    });
    password.addEventListener("blur", () => {
      if (password.value.length == 0) {
        passwordLabel.classList.remove("input-selected");
      } else {
        password.style.borderColor = "#666";
        passwordLabel.style.color = "#000000de";
        if (email.value.length != 0) {
          error.style.visibility = "hidden";
        }
      }
    });
  }, []);

  const navigate = useNavigate();

  // TODO: Move these to .ENV file, better variable names
  let baseURL = `${global.config.domain}/forum-api/`;
  let proxyURL = `${global.config.domain}/express-api/`;

  const loginAttempt = () => {
    setIsSigningIn(true);

    // DOM Element Selection
    email = document.querySelector(".email-login-input");
    password = document.querySelector(".password-login-input");
    emailLabel = document.querySelector(".email-label");
    passwordLabel = document.querySelector(".password-label");
    error = document.getElementById("li-error-text");

    // TODO: Eliminate redundant parameter
    if (email_text.length === 0 && !pass_text.match(passRegex)) {
      email.style.borderColor = "#b50000";
      emailLabel.style.color = "#b50000";
      password.style.borderColor = "#b50000";
      passwordLabel.style.color = "#b50000";
      error.style.visibility = "visible";
    } else if (!pass_text.match(passRegex)) {
      password.style.borderColor = "#b50000";
      passwordLabel.style.color = "#b50000";
      error.style.visibility = "visible";
    } else if (email_text.length === 0) {
      email.style.borderColor = "#b50000";
      emailLabel.style.color = "#b50000";
      error.style.visibility = "visible";
    }
    // TODO: Remove unnecessary else (in favor of fail-first principle)
    else {
      error.style.visibility = "hidden";

      Axios.post(baseURL + "api/v3/utilities/login", {
        username: email_text,
        password: pass_text,
      })
        .then((response) => {
          // TODO: Handle all possible error responses.
          // This only accounts for response 500, and results in cascading failed POSTS to MongoDB.
          if (response.data.length === 0) {
            error.style.visibility = "visible";
            set_email_text("");
            set_pass_text("");
          } else {
            Axios.get(proxyURL + "user", { withCredentials: true })
              .then((response) => {
                const userData = response.data;
                setIsAuthenticated(true);
                setUser(userData);
                setIsSigningIn(false);
                Axios.get(proxyURL + "isAdmin", { withCredentials: true })
                  .then((response) => {
                    setIsAdmin(true);
                  })
                  .catch((error) => {
                    setIsAdmin(false);
                  });
                Axios.get(baseURL + `api/user/${user?.userslug}/settings`, {
                  withCredentials: true,
                })
                  .then((response) => {
                    const userSettings = response.data;
                    setUserSettings(userSettings);
                  })
                  .catch((error) => {
                    console.error(error);
                  });

                // Perform a hard refresh to the homepage as the last step
                // Hopefully this will help the routeguards access user data upon first login
                window.location.replace("/");
              })
              .catch((error) => {
                // TODO: Implement service for error handling
                console.error(error);
                // If the API call fails or the session is no longer valid, set the authentication state to false
                setIsAuthenticated(false);
                error.style.visibility = "visible";
              });
          }
        })
        .catch((err) => {
          console.log(err);
          error.style.visibility = "visible";
        });
    }
  };

  return (
    <div className="login-body">
      <h1>Log in</h1>
      <div className="mid-section">
        <label for="email" className="email-label">
          Username
        </label>
        <input
          type="text"
          name="email"
          className="email-login-input"
          value={email_text}
          onChange={(e) => {
            set_email_text(e.target.value);
          }}
        />
        <label for="password" className="password-label">
          Password
        </label>
        <input
          type="password"
          name="password"
          className="password-login-input"
          value={pass_text}
          onChange={(e) => {
            set_pass_text(e.target.value);
          }}
        />
        <div className="li-error-line">
          <p id="li-error-text">Invalid email and/or password</p>
        </div>
        <div className="login-options">
          <a
            href = {`https://forum.${global.config.domainNoHttps}/reset`}
            target="_blank"
            rel="noreferrer"
          >
            Forgot password
          </a>
          <PrimaryButton
            backgroundColor="#668c3c"
            text="Log in"
            onClick={(e) => {
              loginAttempt();
            }}
          ></PrimaryButton>
        </div>
        {isSigningIn && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        )}
      </div>
      <p>
        Don't have an account? <a href="/sign-up">Sign up</a>
      </p>
    </div>
  );
}

export default Login;
